import React, { Fragment, useEffect, useRef, useState } from 'react';
import ModalVideo from 'react-modal-video';
import tw, { css, styled } from 'twin.macro';

import AmenitiesIcon from '../../assets/icons/amenities.svg';
import BedIcon from '../../assets/icons/bed.svg';
import Play from '../../assets/icons/play.svg';
import ShowerIcon from '../../assets/icons/shower.svg';
import { Text, TextType } from '../../core-ui/Text';
import { getYoutubeVideoId } from '../../utils/getYoutubeVideoId';
import {
  ImgixFluid,
  ProjectAreaDetailFragment,
} from '../../__generated__/types';
import { Img } from '../Img';
import { MobileDropdown } from '../MobileDropdown';
import { ProjectSliceWrapper } from './ProjectSliceWrapper';

type Props = { data?: ProjectAreaDetailFragment | null };

const ModalVideoOption = {
  autoplay: 1,
};

type TabImage = {
  label: string;
  image: ImgixFluid | null | undefined;
  fallbackImageUrl: string;
  dimensions?: { width: number; height: number } | null;
  type: 'image';
};
type TabVideo = {
  label: string;
  url: string;
  type: 'video';
};
export const ProjectBodyAreaDetail: React.FC<Props> = ({ data: res }) => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [isPlayingVideo, setIsPlayingVideo] = useState(false);
  const previewImageRef = useRef<HTMLDivElement>(null);
  if (!res?.data) {
    return null;
  }
  const data = res.data;
  let onClose = () => setIsPlayingVideo(false);
  let playVideo = () => setIsPlayingVideo(true);
  let firstFloorPlanImageFluid = data?.first_tab_image?.fluid;
  let firstFloorPlanImageFallback = data?.first_tab_image;
  let secondFloorPlanImageFluid = data?.second_tab_image?.fluid;
  let secondFloorPlanImageFallback = data?.second_tab_image;
  let walkthroughVideoUrl =
    (data?.walkthrough_video?.thumbnail_url as string) || null;
  let firstFloorPlanDatum: Array<TabImage> =
    data?.first_tab_label &&
    (firstFloorPlanImageFluid || firstFloorPlanImageFallback)
      ? [
          {
            label: data?.first_tab_label,
            image: firstFloorPlanImageFluid,
            fallbackImageUrl: firstFloorPlanImageFallback?.url || '',
            dimensions: firstFloorPlanImageFallback?.dimensions,
            type: 'image',
          },
        ]
      : [];
  let secondFloorPlanDatum: Array<TabImage> =
    data?.second_tab_label &&
    (secondFloorPlanImageFluid || secondFloorPlanImageFallback)
      ? [
          {
            label: data?.second_tab_label,
            image: secondFloorPlanImageFluid,
            fallbackImageUrl: secondFloorPlanImageFallback?.url || '',
            dimensions: secondFloorPlanImageFallback?.dimensions,
            type: 'image',
          },
        ]
      : [];
  let youtubeThumbnailUrl = walkthroughVideoUrl?.replace(
    'hqdefault',
    'maxresdefault',
  );
  let walkthroughDatum: Array<TabVideo> = youtubeThumbnailUrl
    ? [{ label: '3D Walkthrough', url: youtubeThumbnailUrl, type: 'video' }]
    : [];
  let tabOptions: Array<TabImage | TabVideo> = [
    ...firstFloorPlanDatum,
    ...secondFloorPlanDatum,
    ...walkthroughDatum,
  ];

  const [youtubeThumbnail, setYoutubeThumbnail] = useState(youtubeThumbnailUrl);
  const [youtubeThumbnailLoading, setYoutubeThumbnailLoading] = useState(false);
  const imgElement = React.useRef<null | HTMLImageElement>(null);
  useEffect(() => {
    if (imgElement.current?.naturalWidth === 120 && walkthroughVideoUrl) {
      setYoutubeThumbnailLoading(true);
      setYoutubeThumbnail(walkthroughVideoUrl);
      setYoutubeThumbnailLoading(false);
    }
  }, [imgElement, walkthroughVideoUrl]);
  return (
    <ProjectSliceWrapper css={tw`my-36 lg:mt-70 lg:mb-88 flex-wrap`} row>
      <ModalVideo
        channel="youtube"
        isOpen={isPlayingVideo}
        youtube={ModalVideoOption}
        videoId={getYoutubeVideoId(data?.walkthrough_video?.embed_url ?? '')}
        onClose={onClose}
      />
      <div
        css={tw`w-full lg:w-3/12 lg:border-r-1 lg:border-solid lg:border-darkerGrey lg:border-opacity-25`}
      >
        <Text type={TextType.subtitle} css={tw`sm:text-1.7 sm:leading-36`}>
          Detailed Space Area
        </Text>
        <div css={tw`my-24 lg:my-42`}>
          {data?.number_of_bedrooms ? (
            <Bedrooms amount={data.number_of_bedrooms} />
          ) : null}
          {data?.number_of_bathrooms ? (
            <Bathrooms amount={data.number_of_bathrooms} />
          ) : null}
          {data?.amenities ? (
            <Amenities values={data?.amenities.split('\n')} />
          ) : null}
        </div>
      </div>
      <div css={tw`w-full lg:w-9/12 pl-0 lg:pl-88`}>
        {tabOptions.length > 1 ? (
          <Tab
            onClick={setSelectedTab}
            selectedIndex={selectedTab}
            tabOptions={tabOptions}
          />
        ) : null}
        <div css={tw`mt-36 lg:mt-0`}>
          {tabOptions.map((a, index) => {
            let isSelected = selectedTab === index;
            let isTabDisplayed = tabOptions.length > 1;
            if (a.type === 'image') {
              return (
                <Img
                  fluid={a.image}
                  fallbackImage={{
                    url: a.fallbackImageUrl,
                    dimensions: a.dimensions,
                  }}
                  css={
                    isTabDisplayed &&
                    !isSelected &&
                    css`
                      display: none;
                    `
                  }
                />
              );
            }
            const { url } = a;

            return (
              <Fragment key={index}>
                <div
                  ref={previewImageRef}
                  css={[
                    tw`relative`,
                    isTabDisplayed &&
                      !isSelected &&
                      css`
                        opacity: 0;
                      `,
                    (!youtubeThumbnail || youtubeThumbnailLoading || !url) &&
                      tw`hidden`,
                  ]}
                >
                  <img
                    ref={imgElement}
                    src={youtubeThumbnail}
                    onLoad={({ target }) => {
                      if (
                        (target as any).naturalWidth === 120 &&
                        walkthroughVideoUrl
                      ) {
                        setYoutubeThumbnailLoading(true);
                        setYoutubeThumbnail(walkthroughVideoUrl);
                        setYoutubeThumbnailLoading(false);
                      }
                    }}
                    style={
                      tabOptions.length > 0
                        ? isTabDisplayed
                          ? {
                              height: previewImageRef?.current?.offsetWidth
                                ? previewImageRef?.current?.offsetWidth /
                                  (data?.first_tab_image?.fluid?.aspectRatio ??
                                    1.4)
                                : 'auto',
                            }
                          : undefined
                        : {
                            width: youtubeThumbnail?.includes('maxresdefault')
                              ? 'auto'
                              : '100%',
                          }
                    }
                    css={[
                      isTabDisplayed &&
                        !isSelected &&
                        css`
                          display: none;
                        `,
                      css`
                        width: 100%;
                        object-fit: cover;
                        max-width: unset;
                      `,
                    ]}
                  />
                  <div
                    css={css`
                      -webkit-box-shadow: inset -1px -90px 121px -24px rgba(0, 0, 0, 0.75);
                      -moz-box-shadow: inset -1px -90px 121px -24px rgba(0, 0, 0, 0.75);
                      box-shadow: inset -1px -90px 121px -24px rgba(0, 0, 0, 0.75);

                      @media only screen and (min-width: 768px) {
                        -webkit-box-shadow: inset -4px -180px 200px 12px
                          rgba(0, 0, 0, 0.75);
                        -moz-box-shadow: inset -4px -180px 200px 12px
                          rgba(0, 0, 0, 0.75);
                        box-shadow: inset -4px -180px 200px 12px
                          rgba(0, 0, 0, 0.75);
                      }
                      position: absolute;
                      width: 100%;
                      height: 100%;
                      top: 0;
                      margin: auto;
                    `}
                  >
                    <button
                      css={css`
                        width: 100%;
                        height: 100%;
                        display: block;
                      `}
                      onClick={playVideo}
                    >
                      <div
                        css={css`
                          text-align: center;
                          vertical-align: middle;
                          display: inline-block;
                        `}
                      >
                        <Play
                          css={css`
                            width: 36px;
                            @media only screen and (min-width: 1240px) {
                              width: unset;
                            }
                          `}
                        />
                      </div>
                    </button>
                  </div>
                </div>
              </Fragment>
            );
          })}
        </div>
      </div>
    </ProjectSliceWrapper>
  );
};

type DetailedSpaceAreaItemProps = { amount?: number; values?: Array<string> };

export const Bedrooms = ({ amount }: DetailedSpaceAreaItemProps) => {
  if (!amount) {
    return null;
  }
  return (
    <div css={tw`flex first:last:mb-0 mb-16 lg:mb-64`}>
      <BedIcon css={tw`mr-32 h-24 lg:h-32 w-24 lg:w-32`} />
      <DetailedSpaceAreaItemLabel amount={amount} unit="Bedrooms" />
    </div>
  );
};
export const Bathrooms = ({ amount }: DetailedSpaceAreaItemProps) => {
  if (!amount) {
    return null;
  }
  return (
    <div css={tw`flex first:last:mb-0 mb-16 lg:mb-64`}>
      <ShowerIcon css={tw`mr-32 h-24 lg:h-32 w-24 lg:w-32`} />
      <DetailedSpaceAreaItemLabel amount={amount} unit="Bathrooms" />
    </div>
  );
};
export const Amenities = ({ values }: DetailedSpaceAreaItemProps) => {
  if (!values) {
    return null;
  }
  return (
    <div css={tw`flex first:last:mb-0 mb-16 lg:mb-64`}>
      <AmenitiesIcon css={tw`mr-32 h-24 lg:h-32 w-24 lg:w-32`} />
      <DetailedSpaceAreaItemLabel values={values} unit="Amenities:" />
    </div>
  );
};

export const DetailedSpaceAreaItemLabel = ({
  amount,
  unit,
  values,
}: DetailedSpaceAreaItemProps & { unit: string }) => {
  return (
    <div>
      {amount ? (
        <Text type={TextType.caption} css={tw`font-normal mb-8`}>
          {amount}
        </Text>
      ) : null}
      <Text type={TextType.caption}>{unit.toUpperCase()}</Text>
      {values ? (
        <ul css={tw`mb-8`}>
          {values?.map((item) => {
            return (
              <li
                key={item}
                css={tw`text-3xs lg:text-xs leading-20 lg:leading-32`}
              >{`• ${item}`}</li>
            );
          })}
        </ul>
      ) : null}
    </div>
  );
};

export const Tab = ({
  onClick,
  selectedIndex,
  tabOptions,
}: {
  onClick: (index: number) => void;
  selectedIndex: number;
  tabOptions: Array<{ label: string }>;
}) => {
  return (
    <>
      <div css={tw`border-2 border-black w-11/12 mb-40 hidden lg:flex`}>
        {tabOptions.map(({ label }, index) => {
          return (
            <TabButton
              label={label}
              isSelected={selectedIndex === index}
              onClick={onClick}
              key={index}
              index={index}
              css={
                index !== tabOptions.length - 1 && tw`border-r-2 border-black`
              }
            />
          );
        })}
      </div>
      <MobileDropdown
        labels={tabOptions.map(({ label }) => label)}
        selectedIndex={selectedIndex}
        setSelectedIndex={onClick}
      />
    </>
  );
};

type TabButtonProps = {
  isSelected: boolean;
  label: string;
  onClick: (index: number) => void;
  index: number;
};
const TabBaseButton = styled.button<Pick<TabButtonProps, 'isSelected'>>`
  ${tw`py-12 w-1/2 hover:font-bold text-center transition-all duration-100 ease-in-out text-black font-medium`}
  ${({ isSelected }) => isSelected && tw`bg-black text-white font-bold`}
`;

export const TabButton = ({
  isSelected,
  label,
  onClick,
  index,
  ...otherProps
}: TabButtonProps) => {
  const onClickWrapped = () => onClick(index);
  return (
    <TabBaseButton
      isSelected={isSelected}
      onClick={onClickWrapped}
      {...otherProps}
    >
      <Text
        type={TextType.caption}
        css={tw`text-center transition-all duration-100 ease-in-out`}
      >
        {label.toUpperCase()}
      </Text>
    </TabBaseButton>
  );
};
